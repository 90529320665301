import PropTypes from 'prop-types';
import { Component } from 'react';
import styles from './styles/ToggleInput.module.scss';
import classNames from 'classnames'

export default class ToggleSwitch extends Component {
    static propTypes = {
        on: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        enabled: PropTypes.bool,
        wrapperClassName: PropTypes.string,
    };

    static defaultProps = {
        enabled: true,
        onDisabledClick: () => {},
    };

    render() {
        const {on, onClick, enabled, onDisabledClick, wrapperClassName, ...restProps} = this.props;

        const handleKeydown = (evt) => {
            if (evt.code === "Enter") {
                const element = document.activeElement;
                element.click();
            }
        }

        const switchClassName = classNames({
            [styles.switch]: true,
            [styles.disabled]: !enabled,
            [styles.on]: on,
            [wrapperClassName]: wrapperClassName,
        });

        const toggleClassName = classNames({
            [styles.switchToggle]: true,
        });

        return (
            <div
                tabIndex={0}
                onKeyDown={handleKeydown}
                {...restProps}
                className={switchClassName}
                onClick={(e) => enabled ? onClick(e) : onDisabledClick(e)}
            >
                <div className={toggleClassName} />
            </div>
        );
    }
}
