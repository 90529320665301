import {Dispatch, SetStateAction} from "react";
import styles from "./styles/ContentItemSelect.module.scss";
import {RequiredDiv} from "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredDiv";
import {RequiredInput} from "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput";

export function UrlEditor(props: {
    url: string,
    setUrl: Dispatch<SetStateAction<string>>,
    text: string,
    setText: Dispatch<SetStateAction<string>>,
}) {
    return <RequiredDiv required={false} valid={true} className={styles.urlEditor}>
        <input
            placeholder={"Topic Link"}
            value={props.url}
            onChange={e => props.setUrl(e.target.value)}
        />
        <RequiredInput
            required={!!props.url}
            valid={!!props.text}
            className={styles.urlEditor}
            placeholder={"Topic Link Text"}
            value={props.text}
            onChange={e => props.setText(e.target.value)}>
        </RequiredInput>
    </RequiredDiv>
}