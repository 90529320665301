import styles from "../styles/SchoolFeedWidget.module.scss";
import FlatIcon from "../../utilities/FlatIcon";
import {DatePicker} from "./DatePicker";
import moment, {Moment} from "moment";
import {Dispatch, SetStateAction} from "react";

export function DateEditor(props: {
    allDay: boolean,
    setAllDay: Dispatch<SetStateAction<boolean>>,
    startDate: Moment,
    setStartDate: Dispatch<SetStateAction<Moment>>,
    endDate: Moment,
    setEndDate: Dispatch<SetStateAction<Moment>>,
}) {
    return <div className={styles.dates}>
        <div>
            <input type={"checkbox"} checked={props.allDay} id={"allDay"} onChange={() => props.setAllDay(v => !v)}/> <label
            htmlFor={"allDay"}>All Day</label>
        </div>
        <div>
            <FlatIcon name={'flaticon-clock'}/> Start:
            <DatePicker
                value={props.startDate}
                onChange={(date: Moment | string) => {
                    // can be string because of manual changes
                    const newStartDate = typeof date === "string" ? moment(date) : date;
                    props.setStartDate(newStartDate);
                    if (newStartDate.isAfter(props.endDate)) {
                        const newEndDate = newStartDate.clone();
                        newEndDate.set({
                            hour: props.endDate.hour(),
                            minute: props.endDate.minute(),
                        });
                        props.setEndDate(newEndDate);
                    }
                }}
                allDay={props.allDay}
            />
        </div>
        <div>
            <FlatIcon name={'flaticon-clock'}/> End:
            <DatePicker
                value={props.endDate}
                onChange={(date: Moment | string) => {
                    // can be string because of manual changes
                    const newEndDate = typeof date === "string" ? moment(date) : date;
                    props.setEndDate(newEndDate)
                    if (newEndDate.isBefore(props.startDate)) {
                        const newStartDate = newEndDate.clone();
                        newStartDate.set({
                            hour: props.startDate.hour(),
                            minute: props.startDate.minute(),
                        })
                        props.setStartDate(newStartDate);
                    }
                }}
                allDay={props.allDay}
            />
        </div>
    </div>
}