import className from "classnames";
import styles from "./RequiredInput.module.scss";

export function RequiredDiv(props: {
    valid: boolean,
    required?: boolean,
    [key: string]: any,
}) {
    const {valid, required=true, children, ...validHtmlProps} = props;

    const inputClassName = className({
        [props.className || ""]: true,
        [styles.div]: required,
        [styles.divValid]: required && valid,
    })
    return <div {...validHtmlProps} className={inputClassName}>
        {children}
    </div>
}