import styles from "../styles/SchoolFeedWidget.module.scss";
import FlatIcon from "../../utilities/FlatIcon";
import {SetStateAction} from "react";

export function LocationEditor(props: {
    location: string,
    setLocation: SetStateAction<any>,
}) {
    return <div className={styles.location}>
        <div>
            <FlatIcon name={'flaticon-map'}/>
            <input value={props.location} onChange={e => props.setLocation(e.target.value)} type={"text"}
                   placeholder={"123 Main St"}/>
        </div>
    </div>
}