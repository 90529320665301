import {useContext, useEffect, useState} from 'react';
import styles from "../styles/SchoolFeedWidget.module.scss";
import listItemStyles from "../styles/ListItemGroup.module.scss";
import FlatIcon from "../../utilities/FlatIcon";
import {getCdnUrl} from "../../../utils/SchoolBlocksUtilities";
import FontAwesome from "../../utilities/FontAwesome";
import moment, {Moment} from 'moment';
import {StoreContext} from "../../../stores/StoreLoader";
import classNames from 'classnames';
import {PartialsController} from "../../../backends/YiiRoutes";
import {observer} from "mobx-react";
import ImageComponent from "../../utilities/ImageComponent";
import {getIconByMimeType} from "../../blocks/TerracedGridTheme/FilesFoldersBlock/fileUtilities";
import {getConfig} from "../../../_configs/AppConfig";
import MarkdownEditor from "../../editors/MarkdownEditor";
import {isEvent, isFile, isFolder, isMedia, isNews} from "../ListItemGroup";
import {LocationEditor} from "./LocationEditor";
import {DateEditor} from "./DateEditor";
import {ContentItemSelect} from "./ContentItemSelect";
import {useGridType} from "../../../hooks/useGridType";
import {GridTypes} from "../../../pages/MainGridPage";
import {ITerraceTypes, terraceTypeToTitleMap} from "../../blocks/TerracedGridTheme/Terrace";
import {values} from "mobx";
import NotificationManager from "../../notifications/NotificationManager";

import {RequiredInput} from "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredInput";
import {RequiredDiv} from "../../blocks/SchoolBlocks/EditBlockComponents/EditBlockInputs/RequiredDiv";
import {useMachine} from "@xstate/react";
import {FetchMachine} from "../../machines/FetchMachine";
import WatsonApi from "../../../backends/WatsonApi";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import ToggleSwitch from "../../admin/sidebar/items/ToggleSwitch";
import {
    ReactiveContentItem, IReactiveCourseWorkContentItem,
    IReactiveEventContentItem,
    IReactiveNewsContentItem, IReactiveMediaContentItem,
} from "../../../stores/SchoolFeedStore";
import {useCreateContentItem} from "../../../hooks/useCreateContentItem";
import useSWR from "swr";
import {UrlEditor} from "./UrlEditor";
import {ImageEditor} from "./ImageEditor";
import {SharePathsEditor} from "./SharePathsEditor";

const submitDateFormat = "YYYY-MM-DDTHH:mm";
export const displayDateFormat = "dddd, MMM D";

const {publicRuntimeConfig} = getConfig();

export function getDefaultTimes() {
    const start = moment();
    const minutes = start.minutes();
    if (minutes % 15 !== 0) {
        start.minutes(Math.ceil(minutes / 15) * 15);
    }
    return {
        start,
        end: moment(start).add(1, "hour"),
    };
}

export function getTerraceTypeFromContentItem(contentItem: ContentItem): ITerraceTypes | undefined {
    if (isNews(contentItem)) {
        return ITerraceTypes.NEWS;
    } else if (isFile(contentItem) || isFolder(contentItem)) {
        return ITerraceTypes.FILES;
    } else if (isEvent(contentItem)) {
        return ITerraceTypes.EVENTS;
    } else if (isMedia(contentItem)) {
        return ITerraceTypes.MEDIA
    }
}

export const enum SupportedContentItemTypes {
    NEWS = "news",
    MEDIA = "media",
    FILE = "files",
    EVENT = "events",
}

export function getTerraceTypeFromSupportedContentItemType(contentItemType: SupportedContentItemTypes): ITerraceTypes {
    switch (contentItemType) {
        case SupportedContentItemTypes.EVENT:
            return ITerraceTypes.EVENTS
        case SupportedContentItemTypes.FILE:
            return ITerraceTypes.FILES
        case SupportedContentItemTypes.NEWS:
            return ITerraceTypes.NEWS
        case SupportedContentItemTypes.MEDIA:
            return ITerraceTypes.MEDIA
    }
}

export function typeFromContentItem(contentItem) {
    if (!contentItem) return null;
    else if (contentItem.is_news) return SupportedContentItemTypes.NEWS;
    else if (contentItem.is_media) return SupportedContentItemTypes.MEDIA;
    else if (contentItem.is_event) return SupportedContentItemTypes.EVENT;
    else if (contentItem.is_file || contentItem.is_folder) return SupportedContentItemTypes.FILE;
    return null;
}

export type IMinimalOrganization = Pick<OrganizationTypeOrganization, "id" | "title" | "path"> & {
    requires_request: boolean,
    is_primary: boolean,
}

export const SchoolFeedPostManager = observer((props: {
    contentItem: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveMediaContentItem | null,
}) => {
    const [current, send] = useMachine(FetchMachine);
    const {
        organizationStore,
        modalStore,
        i18nStore,
        gridStore,
        sidebarStore,
        interfaceStore
    } = useContext(StoreContext);

    const gridType = useGridType();
    const {handleSave} = useCreateContentItem();

    const [contentItemType, setContentItemType] = useState<SupportedContentItemTypes | null>(null);
    const [postToFacebook, setPostToFacebook] = useState<boolean>(false);
    const [meritChatEnabled, setMeritChatEnabled] = useState(organizationStore.currentOrganization.json_data.settings?.meritchat?.enabled);
    const [published, setPublished] = useState(true);
    const [contentItem, setContentItem] = useState<ContentItem | null>(null);

    useAsyncEffect(async () => {
        if (props.contentItem) {
            try {
                send("FETCH");
                const client = await WatsonApi();
                const result = await client.apis.organizations.organizations_content_items_read({
                    organization_pk: props.contentItem.organization_id,
                    id: props.contentItem?.id,
                    expand: [
                        "organization",
                        "organization.is_editor",
                        "share_organizations",
                        "share_request_organizations",
                    ],
                });
                const resultObj = new ReactiveContentItem(result.obj);

                setContentItem(resultObj)
                send("FULFILL");
            } catch (e) {
                send("REJECT");
                NotificationManager.error('Content Item not found');
            }
        }
    }, [])

    const {data: currentFacebookPage} = useSWR(`get-facebook-page-${organizationStore.currentOrganization.id}`, async () => {
        const client = await WatsonApi();
        const response = await client.apis.organizations.organizations_facebook_token_retrieve_token({
            organization_pk: organizationStore.currentOrganization.id,
        })
        const result = JSON.parse(response.data);
        return result.page_name;
    }, {
        revalidateIfStale: false,
        revalidateOnMount: false,
    })

    useEffect(() => {
        if (sidebarStore.sidebar?.sbtype) {
            setContentItemType(sidebarStore.sidebar.sbtype as SupportedContentItemTypes);
        } else if (!contentItem) {
            setContentItemType(null);
        }
    }, [sidebarStore.sidebar, contentItem])

    const [title, setTitle] = useState<string>("");
    // Need to set the prop content item description, Slate editor takes a default value prop that does not
    // change after the value is updated
    const [text, setText] = useState<string>(props.contentItem?.description || "");
    const [altText, setAltText] = useState<string>("");
    const [image, setImage] = useState<string>("");

    const defaultTimes = getDefaultTimes();
    const [allDay, setAllDay] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Moment>(defaultTimes.start);
    const [endDate, setEndDate] = useState<Moment>(defaultTimes.end);
    const [location, setLocation] = useState<string>("");
    const [selectedOrganizations, _setSelectedOrganizations] = useState<IMinimalOrganization[]>([organizationStore.currentOrganization]);
    const [organizationId, setOrganizationId] = useState<string | undefined>(props.contentItem?.organization_id || organizationStore.currentOrganization.id)

    const [shareRequestOrganizations, setShareRequestOrganizations] = useState<IMinimalOrganization[]>([]);

    const [showDateSelector, setShowDateSelector] = useState<boolean>(false);
    const [showLocationEditor, setShowLocationEditor] = useState<boolean>(false);
    const [showUrlEditor, setShowUrlEditor] = useState<boolean>(false);

    const [file, setFile] = useState<null | IGoogleDriveFile>(null);

    const [url, setUrl] = useState<string>("");
    const [urlText, setUrlText] = useState<string>("");

    useEffect(() => {
        if (contentItem) {
            setOrganizationId(contentItem.organization_id);
            setTitle(contentItem?.title || "");
            setText(contentItem?.description || "");
            setContentItemType(() => {
                return typeFromContentItem(contentItem);
            });
            setMeritChatEnabled(contentItem?.meritchat_enabled || false);
            setPublished(contentItem?.published);
            setAltText(() => {
                if (isNews(contentItem)) {
                    return contentItem.json_data.news.cover_image_alt_text || contentItem?.title || "";
                } else if (isMedia(contentItem) && contentItem.type.includes("photo")) {
                    return contentItem.json_data.images?.[0].alt_text || contentItem?.title || "";
                }
                return ""
            });
            setImage(() => {
                if (isNews(contentItem)) {
                    return contentItem.json_data.news.cover_image_url || "";
                } else if (isEvent(contentItem)) {
                    return contentItem.json_data.event.full_picture || "";
                } else if (isFile(contentItem)) {
                    return contentItem.json_data.file.cover_image_url || "";
                } else if (isMedia(contentItem) && contentItem.type.includes("photo")) {
                    // custom content items can't have videos so we don't need to handle those
                    return contentItem.json_data.images?.[0].raw_url || "";
                }
                return ""
            });

            setAllDay(isEvent(contentItem) ? contentItem.json_data.event.all_day : false);
            setStartDate(isEvent(contentItem) ? moment(contentItem.json_data.event.start) : defaultTimes.start);
            setEndDate(isEvent(contentItem) ? moment(contentItem.json_data.event.end) : defaultTimes.end);
            setLocation(isEvent(contentItem) ? contentItem.json_data.event.location : "");
            // @ts-ignore
            _setSelectedOrganizations(contentItem.share_organizations.sort((a, b) => {
                if (a.path === contentItem.organization.path) {
                    // for the purposes of the UI here, we treat the first share path as the content item's organization,
                    // so we need to make sure it comes first here
                    return -1;
                }
                return 1;
            }));
            // @ts-ignore
            setShareRequestOrganizations(contentItem.share_request_organizations);
            setShowDateSelector(isEvent(contentItem) || false);
            setShowLocationEditor(isEvent(contentItem) && !!contentItem.json_data.event.location || false);

            setFile(isFile(contentItem) ?
                {
                    mimeType: contentItem.json_data.file.mime_type,
                    embedUrl: contentItem.json_data.file.view_link,
                    id: contentItem.third_party_id,
                } as IGoogleDriveFile : null);

            if (isNews(contentItem)) {
                setUrl(contentItem?.json_data.news?.topic_url || "");
                setUrlText(contentItem?.json_data.news?.topic_url_text || "");
            }
        }
    }, [contentItem])

    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string | null>(null);

    async function handleSubmit() {
        let newItem: any = {};
        if (contentItem && !contentItem.is_custom) {
            newItem = {
                id: contentItem.id,
                organization_id: contentItem.organization_id,
                user_published: published,
            }
            if (contentItemType === SupportedContentItemTypes.NEWS) {
                newItem.meritchat_enabled = meritChatEnabled;
            }
        } else {
            newItem = {
                title,
                organization_id: organizationId,
                description: text,
                source_id: contentItem ? contentItem.source_id : -1,
                is_event: false,
                is_news: false,
                is_file: false,
                is_media: false,
                json_data: {},
                user_published: published,
                share_paths: [
                    ...selectedOrganizations.map(o => o.path),
                    ...shareRequestOrganizations.map(o => o.path),
                ],
            };

            if (contentItem) {
                newItem.id = contentItem.id;
                newItem.organization_id = contentItem.organization_id;
            }

            if (contentItemType === SupportedContentItemTypes.EVENT) {
                newItem.is_event = true;
                newItem.type = "custom.event";
                newItem.third_party_id = `custom_event_${Date.now()}`;
                newItem.created_at = startDate.format(submitDateFormat);
                newItem.json_data.event = {
                    end: endDate.format(submitDateFormat),
                    start: startDate.format(submitDateFormat),
                    location: showLocationEditor ? location : "",
                    multi_day: !endDate.isSame(startDate, 'day'),
                    full_picture: image ? image : "",
                    full_picture_alt_text: altText,
                    start_date_day: startDate.format('DD'),
                    start_day_month: startDate.format('MMM'),
                    end_date_day: endDate.format('DD'),
                    end_day_month: endDate.format('MMM'),
                    all_day: allDay,
                }
            } else if (contentItemType === SupportedContentItemTypes.FILE && file) {
                newItem.is_file = true;
                newItem.type = "drive.file";
                newItem.third_party_id = file.id;
                newItem.json_data.file = {
                    cover_image_url: image ? image : "",
                    cover_image_alt_text: altText,
                    view_link: file.url,
                    mime_type: file.mimeType,
                    updated_at: moment.utc(file.lastEditedUtc).format(submitDateFormat),
                }
            } else if (contentItemType === SupportedContentItemTypes.NEWS) {
                newItem.is_news = true;
                newItem.type = "custom.news";
                newItem.third_party_id = `custom_news_${Date.now()}`;
                newItem.json_data.news = {
                    cover_image_url: image ? image : "",
                    cover_image_alt_text: altText,
                    topic_url: showUrlEditor ? url : undefined,
                    topic_url_text: showUrlEditor ? urlText : undefined,
                }
                newItem.meritchat_enabled = meritChatEnabled;
            } else if (contentItemType === SupportedContentItemTypes.MEDIA) {
                newItem.is_media = true;
                newItem.type = "custom.photo";
                newItem.third_party_id = `custom_image_${Date.now()}`;
                newItem.json_data.images = [{
                    raw_url: image ? image : "",
                    alt_text: altText,
                }]
            }
        }

        try {
            if (props.contentItem) {
                send("FETCH");
                const client = await WatsonApi();
                const result = await client.apis.organizations.organizations_content_items_partial_update({
                    organization_pk: newItem.organization_id,
                    id: newItem.id,
                    data: {
                        ...newItem,
                        organization_id: newItem.organization_id,
                    },
                });
                props.contentItem && props.contentItem.update({...result.obj});
            } else {
                send("FETCH");
                if (sidebarStore.sidebar.handleSave) {
                    sidebarStore.sidebar.handleSave(newItem, selectedOrganizations[0], postToFacebook);
                } else {
                    await handleSave(newItem, selectedOrganizations[0], postToFacebook);
                }
            }

            NotificationManager.success(`Item ${contentItem ? "updated" : "created"} successfully.`)
            await sidebarStore.close();
            send("FULFILL");
        } catch (e) {
            console.log(e);
            send("REJECT");
            NotificationManager.error("Whoops, something went wrong!");
        }
    }

    async function handleDelete(contentItem) {
        try {
            send("FETCH");
            await sidebarStore.sidebar.handleDelete(contentItem);
            await sidebarStore.close();
            NotificationManager.success("Item deleted.")
            send("FULFILL");
        } catch (e) {
            send("REJECT");
            NotificationManager.error("Whoops, something went wrong!");
        }
    }

    let media;
    if (image) {
        media = <>
            <img src={getCdnUrl(image)}/>
            <button onClick={() => {
                setImage("");
            }}><FontAwesome name={'fa-times'} prefix={'fas'}/></button>
        </>
    } else if (contentItemType === SupportedContentItemTypes.EVENT && startDate) {
        media = <div className={listItemStyles.dateMedia}>
            <span>{startDate.format("MMM")}</span>
            <span>{startDate.format("D")}</span>
        </div>
    }

    const contentContainer = classNames({
        [styles.imageContainer]: true,
        [styles.error]: uploadError,
        [listItemStyles.groupContainer]: image,
    })

    const messageContainer = classNames({
        [styles.editor]: true,
        [styles.required]: postToFacebook && !text,

    })

    const disableSubmitButton = uploading ||
        current.value === "PENDING" ||
        !title ||
        (contentItemType === SupportedContentItemTypes.FILE && !file) ||
        (contentItemType === SupportedContentItemTypes.MEDIA && !image) ||
        (image && !altText) ||
        (url && !urlText) ||
        (postToFacebook && !text) ||
        selectedOrganizations.length === 0 ||
        !organizationId;

    const submitButtonClassName = classNames({
        [styles.submitButton]: true,
        [styles.submitButtonDisabled]: disableSubmitButton,
    })

    const editContentClassName = classNames({
        [styles.cantEditContent]: contentItem && !contentItem?.is_custom,
    })

    if (!props.contentItem && !contentItemType) {
        return <ContentItemSelect setContentItemType={async type => {
            await sidebarStore.setSidebar({
                ...sidebarStore.sidebar,
                sbtype: type,
            })
        }}/>
    }

    function handleOpenSourcePage() {
        sidebarStore.setSidebar({view: 'EditSource'})
        sidebarStore.setQueriesToOnly(["spview", "EditSource"], ["id", contentItem?.source_id])
    }

    return <>
        {current.value === "PENDING" ?
            <FontAwesome className={styles.loadingSpinner} prefix={"fas"} name={"fa-spinner"} spin size={'2x'}/>
            :
            <>
                {(contentItem && !contentItem?.organization?.is_editor) ?
                    <div
                        className={styles.noPermissionText}>{`This content item is owned by ${contentItem?.organization?.title}, please contact the administrators of that 
                page if you would like to make changes to this item.`}</div>
                    :
                    <div className={`${styles.postContainer} notranslate`}>
                        <div>
                            {contentItem && !contentItem?.is_custom &&
                                <div className={styles.alertMessage}>
                                    <h3>Alert: This content item was imported and is therefore uneditable.</h3>
                                    <hr/>
                                    {contentItem && (contentItem.is_file || contentItem.is_folder) && contentItem.type.includes("drive") &&
                                        <div className={styles.descriptionEditingInstructions}>
                                            <h4>To make edits to the Content Item:</h4>
                                            <ol>
                                                <li>Locate the {contentItem.is_file ? "file" : "folder"} in Google
                                                    Drive
                                                </li>
                                                <li>To add a description
                                                    <ol>
                                                        <li>Right click the {contentItem.is_file ? "file" : "folder"} in
                                                            Google Drive.
                                                        </li>
                                                        <li>Select File Information {'>'} Details.</li>
                                                        <li>Scroll down on the right side information panel to add/edit
                                                            a 'Description'.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                            <span>The description should be updated after the source refreshes which you can manually do from our <button
                                                onClick={handleOpenSourcePage}>Content Source Manager</button></span>
                                        </div>
                                    }
                                </div>
                            }
                            {<div className={editContentClassName}
                                  title={contentItem && !contentItem?.is_custom ? 'Non-custom content items cannot be edited' : undefined}>
                                <div>
                                    {contentItemType &&
                                        gridType === GridTypes.terraced && interfaceStore.view === "default" &&
                                        values(gridStore.blocks).findIndex(t => t.type === contentItemType) === -1 &&
                                        <div style={{marginBottom: '10px'}}>
                                            Item will not be visible on page
                                            unless {terraceTypeToTitleMap[getTerraceTypeFromSupportedContentItemType(contentItemType)]} Terrace
                                            is enabled!
                                        </div>}
                                    {uploadError && <div className={styles.error}>
                                        <p>{uploadError}</p>
                                    </div>}
                                    <div className={contentContainer}>
                                        {media}
                                        <div>
                                            <RequiredInput required={true} valid={!!title}
                                                           placeholder={"Add a Title (required)"} value={title}
                                                           onChange={e => setTitle(e.target.value)}/>
                                            {image && <RequiredInput required={true} valid={!!altText}
                                                                     placeholder={"Image Alt Text (required)"}
                                                                     value={altText}
                                                                     onChange={e => setAltText(e.target.value)}/>}
                                            <MarkdownEditor editorClassName={messageContainer} value={text}
                                                            handleTextChange={setText}
                                                            placeholder={"What would you like to say?"}/>
                                            {contentItemType === SupportedContentItemTypes.FILE &&
                                                <p>If you would like to add a folder rather than an individual file, you
                                                    can add it as a Content Source in our <button
                                                        style={{color: '#1700a1', whiteSpace: 'nowrap'}}
                                                        onClick={() => {
                                                            sidebarStore.setSidebar({
                                                                sbtype: 'GOOGLE_DRIVE',
                                                                view: 'AddSocial'
                                                            })
                                                        }}>Content Source manager</button></p>}
                                        </div>

                                    </div>
                                    {showUrlEditor && <UrlEditor
                                        url={url}
                                        setUrl={setUrl}
                                        text={urlText}
                                        setText={setUrlText}
                                    />}
                                    {showDateSelector && <DateEditor
                                        allDay={allDay}
                                    setAllDay={setAllDay}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                                }
                                {showLocationEditor && <LocationEditor setLocation={setLocation} location={location}/>}
                                {file &&
                                <div className={styles.fileUrl}>
                                    {<ImageComponent
                                        src={getIconByMimeType("google", file.mimeType)}/>}<span>{file.name}
                                    <button onClick={() => {
                                        setFile(null);
                                    }}>
                                                    <FontAwesome prefix={'fas'} name={'fa-times'}/>
                                                </button></span>

                                </div>
                                }

                                <div className={styles.buttons}>
                                    <ImageEditor
                                        image={image}
                                        setImage={setImage}
                                        file={file}
                                        uploading={uploading}
                                        setUploading={setUploading}
                                        setUploadError={setUploadError}
                                        contentItemType={contentItemType}
                                    />
                                    {contentItemType === SupportedContentItemTypes.NEWS &&
                                        <RequiredDiv required={false} valid={true}>
                                            <button
                                                title={"Add a link"}
                                                onClick={() => setShowUrlEditor(v => !v)}
                                            >
                                                <FlatIcon name={'flaticon-link'}/>
                                                Link
                                            </button>
                                        </RequiredDiv>}
                                    {contentItemType === SupportedContentItemTypes.EVENT &&
                                        <RequiredDiv required={false} valid={true}>
                                            <button
                                                title={"Add a location"}
                                                onClick={() => setShowLocationEditor(v => !v)}
                                            >
                                                <FlatIcon name={'flaticon-location'}/>
                                                Location
                                            </button>
                                        </RequiredDiv>}
                                    {contentItemType === SupportedContentItemTypes.EVENT &&
                                        <RequiredDiv required={false} valid={true}>
                                            <button
                                                title={"Add a date"}
                                                onClick={() => {
                                                    setShowDateSelector(v => !v)
                                                }}>
                                                <FlatIcon name={'flaticon-event'}/>
                                                Date
                                            </button>
                                        </RequiredDiv>}
                                    {contentItemType === SupportedContentItemTypes.FILE && <RequiredDiv valid={!!file}>
                                        <button
                                            title={"Add a file"}
                                            onClick={() => {
                                                modalStore.addModal({
                                                    type: "googleFilePicker",
                                                    handlePicked: data => {
                                                        setFile(data);
                                                    },
                                                    role: "sender",
                                                    viewIds: ["DOCS"],
                                                    src: publicRuntimeConfig.API_PHP_URL + PartialsController.googleFilePicker(i18nStore.currentOrgSupportedLocale),
                                                })
                                            }}>
                                            <FlatIcon name={'flaticon-drive'}/>
                                            File
                                        </button>
                                    </RequiredDiv>}
                                </div>

                            </div>
                                </div>}
                            {(contentItem?.is_custom || !contentItem) && <SharePathsEditor
                                contentItem={contentItem}
                                selectedOrganizations={selectedOrganizations}
                                shareRequestOrganizations={shareRequestOrganizations}
                                setSelectedOrganizations={_setSelectedOrganizations}
                                setOrganizationId={setOrganizationId}
                                setShareRequestOrganizations={setShareRequestOrganizations}
                            />}
                            {(contentItemType === SupportedContentItemTypes.NEWS || contentItemType === SupportedContentItemTypes.MEDIA) &&
                                <div>
                                    {contentItem ?
                                        <>
                                            {contentItem?.is_custom &&
                                                <p>Editing content on SchoolBlocks will not affect content posted to
                                                    third party sites.</p>}
                                        </> :
                                        <div className={styles.newsPostOption}>
                                            <ToggleSwitch
                                                enabled={currentFacebookPage}
                                                on={postToFacebook}
                                                onClick={() => setPostToFacebook(!postToFacebook)}
                                            />
                                            <button title={"Manage Social Media Integration"}
                                                    onClick={() => modalStore.addModal({
                                                        type: "notificationChannels",
                                                    })}>
                                                <label>{currentFacebookPage ? "Post" : "Connect"} to
                                                    Facebook</label>
                                            </button>
                                        </div>}
                                    {process.env.MERITOCRACY_CHAT_ENABLED && organizationStore.currentOrganization.json_data.settings.meritchat?.enabled && contentItemType === SupportedContentItemTypes.NEWS &&
                                        <div className={styles.newsPostOption}>
                                            <ToggleSwitch
                                                enabled={true}
                                                on={meritChatEnabled}
                                                onClick={() => setMeritChatEnabled(v => !v)}
                                            />
                                            <label>Discussion Enabled<span
                                                className={styles.tooltip}
                                                data-content={"Connect this content with Meritocracy Chat\n discussion thread to facilitate productive\n and responsible conversation."}
                                                style={{marginLeft: ".25rem"}}
                                            ><FontAwesome prefix={'fas'} name={'fa-question-circle'}/></span></label>
                                        </div>}
                                </div>
                            }
                            {contentItemType && <div className={styles.newsPostOption}>
                                <ToggleSwitch
                                    enabled={true}
                                    on={published}
                                    onClick={() => setPublished(v => !v)}
                                />
                                <label>Published<span
                                    className={styles.tooltip}
                                    data-content={"Toggle this content item's visibility"}
                                    style={{marginLeft: ".25rem"}}
                                ><FontAwesome prefix={'fas'} name={'fa-question-circle'}/></span></label>
                            </div>}
                        </div>
                        <div className={styles.submitButtonContainer}>
                            <button className={submitButtonClassName}
                                    onClick={handleSubmit}>{contentItem ? "Save" : "Post"}</button>
                            {contentItem && contentItem.is_custom && <button className={styles.submitButton}
                                                                             onClick={() => handleDelete(contentItem)}>Delete</button>}
                        </div>
                    </div>}
            </>
        }
    </>
});
