import {Moment} from "moment";
import {TimePicker} from "../../utilities/TimePicker";
import styles from "../styles/SchoolFeedWidget.module.scss";
import ReactDatetimeClass from "react-datetime";
import {displayDateFormat} from "./SchoolFeedPostManager";

export function DatePicker(props: {
    value: Moment,
    onChange: (date: Moment) => void,
    allDay: boolean,
}) {
    return <>
        {props.allDay || <>
            <TimePicker value={props.value} onChange={props.onChange} className={styles.timePicker} />
            <span style={{fontSize: "1.2rem", paddingRight: ".3rem"}}>on</span>
        </>}
        <ReactDatetimeClass
            value={props.value}
            onChange={props.onChange}
            dateFormat={displayDateFormat}
            timeFormat={false}
            closeOnSelect={true}
            className={styles.reactDateTime}
        />
    </>
}