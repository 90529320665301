import {RefObject, useEffect, useMemo, useRef, useState} from "react";
import styles from "./styles/TimePicker.module.scss";
import moment, {Moment} from 'moment';
import useClickOutside from "../../hooks/useClickOutside";
import classNames from 'classnames';
import {getDefaultTimes} from "../schoolfeed/PostManager/SchoolFeedPostManager";

const timeFormat = "h:mma";

export function TimePicker(props: {
    value: Moment,
    onChange: (value: Moment) => void,
    className?: string,
}) {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(props.value.format(timeFormat));
    const [width, setWidth] = useState(50);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const displayRef = useRef<HTMLSpanElement | null>(null);
    const ref: RefObject<HTMLDivElement> = useClickOutside(() => {
        if (editing) {
            setEditing(false)
        }
    });
    const popupRef = useRef<HTMLDivElement | null>(null);

    const style = useMemo(() => {
        if (inputRef?.current) {
            const box = inputRef.current.getBoundingClientRect();
            return {
                top: box.top + box.height + 5,
                left: box.left,
            }
        }
        return {}
    }, [inputRef?.current, editing]);

    const options = useMemo(() => {
        const o: Array<string> = [];
        let h = 0;
        while (h < 2345) {
            h += 15;
            if (/\d\d60/.exec(String(h).padStart(4, "0"))) {
                h += 40;
            }
            const t = moment(String(h).padStart(4, "0"), "HHmm");
            o.push(t.format(timeFormat));
        }
        return o;
    }, [])

    function handleClick() {
        if (!editing) {
            setEditing(true);
        }
    }

    function handleKeyDown(e) {
        if (e.code === "ArrowDown" && document.activeElement === inputRef?.current) {
            // open editor
            setEditing(true);
        } else if (e.code === "Escape" && ref?.current && ref.current.contains(document.activeElement) && editing) {
            // close editor
            setEditing(false);
        } else if (
            e.code === "ArrowDown" &&
            editing && popupRef?.current &&
            popupRef.current.contains(document.activeElement) &&
            document.activeElement?.nextSibling
        ) {
            // focus on next time
            (document.activeElement.nextSibling as HTMLLIElement).focus();
        } else if (
            e.code === "ArrowUp" &&
            editing &&
            popupRef?.current &&
            popupRef.current.contains(document.activeElement) &&
            document.activeElement?.previousSibling
        ) {
            // focus on previous time
            (document.activeElement.previousSibling as HTMLLIElement).focus();
        } else if (
            e.code === "Enter" &&
            editing &&
            popupRef?.current &&
            popupRef.current.contains(document.activeElement) &&
            document.activeElement
        ) {
            setValue(document.activeElement.getAttribute("data-time") as string);
            setEditing(false);
        }
    }

    function handleTimeHover(e) {
        // uses this weird way to show hover effect and not CSS so background shows on initial open on the selected time
        const selected: NodeListOf<HTMLLIElement> | null = document.querySelectorAll(`.${styles.container} li[data-selected="true"]`);
        selected.forEach(s => s.setAttribute("data-selected", "false"));
        (e.target as HTMLLIElement).setAttribute("data-selected", "true");
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [editing]);

    useEffect(() => {
        if (!editing && displayRef?.current) {
            displayRef.current.style.display = "";
            const box = displayRef.current?.getBoundingClientRect();
            displayRef.current.style.display = "none";
            setWidth(box.width);
        }
    }, [props.value]);

    useEffect(() => {
        // after click outside editing and value change, handle it
        if (editing) {
            const selected: HTMLLIElement | null = document.querySelector(`.${styles.container} li[data-selected="true"]`);
            if (selected && popupRef?.current) {
                popupRef.current.scrollTop = selected.offsetTop - 100 + (selected.clientHeight / 2);
                selected.focus();
            } else if (popupRef?.current) {
                // if there isn't a perfect match for the selected time, find the nearest time
                const closestTime = getDefaultTimes().start.format("h:mma");
                const closest: HTMLLIElement | null = document.querySelector(`.${styles.container} li[data-time="${closestTime}"]`);
                if (closest) {
                    popupRef.current.scrollTop = closest.offsetTop - 100 + (closest.clientHeight / 2);
                }
            }
        }
        if (!editing && value !== props.value.format(timeFormat)) {
            const newMoment = moment(value, timeFormat);
            if (/\d{1,2}:\d{1,2}(am|pm)/.exec(value) && newMoment.isValid()) {
                const prevMoment = props.value.clone();
                prevMoment.set({
                    hour: newMoment.hour(),
                    minute: newMoment.minute(),
                });
                props.onChange(prevMoment);
                setValue(prevMoment.format(timeFormat));
            } else {
                setValue(props.value.format(timeFormat));
            }
        }
    }, [editing]);

    const containerClassName = classNames({
        [styles.container]: true,
        [props.className as string]: !!props.className,
    })

    return <span ref={ref} className={containerClassName}>
        <span onClick={handleClick}>
            <span ref={displayRef} style={{display: "none"}}>{props.value.format(timeFormat)}</span>
            <input ref={inputRef} style={{width}} value={value} onChange={e => setValue(e.target.value)}/>
        </span>
        {editing && <div ref={popupRef} style={style}>
            <ul>
                {options.map(option => <li
                    tabIndex={-1}
                    data-selected={option === props.value.format(timeFormat)}
                    data-time={option}
                    onMouseEnter={handleTimeHover}
                    onFocus={handleTimeHover}
                    onClick={() => {
                        setValue(option);
                        setEditing(false);
                    }}
                >{option}</li>)}
            </ul>
        </div>}
    </span>
}