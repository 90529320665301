import { Component } from "react";
import classNames from "classnames";

import styles from "../../blocks/StandardTheme/styles/VideoBlock.module.scss";

export default class IframeVideo extends Component {
    render() {
        const { title, embedLink, backgroundType, isBackground, ...validHtmlProps } = this.props;
        const embedResponsiveItemClassName = classNames({
            [styles.embedResponsiveItem]: true,
        });
        return (
            <iframe
                className={embedResponsiveItemClassName}
                title={title}
                src={embedLink}
                {...validHtmlProps}
            />
        );
    }
}
